<template>
  <div style="background-color: rgb(247, 247, 247);">
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <!-- ======  面包屑  ====== -->
    <!-- 面包屑 -->
    <div class="#" style="padding-top: 22px;margin: 0 auto;max-width: 1200px;box-sizing: border-box;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item to="/PersonalHome">个人中心</el-breadcrumb-item> -->
        <el-breadcrumb-item>申请证书</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- ======  列表  ====== -->

    <div class="container">
      <div class="all">
        <div class="all-book">
          <div>
            <h3 style="float: left;margin-right: 20px;padding-right: 20px;border-right: 2px solid #93939330;">全部证书</h3>
            <div class="year-btn" v-if="this.yearState == 1">
              <div class="year-word">年度</div>
              <div style="width: 16px; cursor: pointer;position: relative; height: 32px;font-size: 12px;"
                @click="currentChangeHandle">
                <span style="display:inline-block; position: absolute; top: 5px; left: 2px;">
                  <i class="el-icon-caret-top" style="color: #1371E7;"></i>
                </span>
                <span style="display:inline-block; position: absolute;top: 14px; left: 2px;">
                  <i class="el-icon-caret-bottom"></i>
                </span>
              </div>
            </div>
            <div class="year-btn" v-if="this.yearState == 2">
              <div class="year-word">年度</div>
              <div style="width: 16px;position: relative; cursor: pointer;height: 32px;font-size: 12px;"
                @click="currentChangeHandle">
                <span style="display:inline-block; position: absolute; top: 5px;left: 2px;">
                  <i class="el-icon-caret-top"></i>
                </span>
                <span style="display:inline-block; position: absolute;top: 14px;left: 2px;">
                  <i class="el-icon-caret-bottom" style="color: #1371E7;"></i>
                </span>
              </div>
            </div>
          </div>

          <div>共找到<span>{{ this.page.totalPage }}</span>个结果</div>
        </div>

        <div>

          <div class="certificateList" v-loading="listItemLoading">
            <div class="certificateList-item" v-for="(item, index) in listItem" :key="index">
              <div class="certificateList-item-left">
                <span class="certificateList-item-left-title">{{ item.name }}</span>
                <span class="certificateList-item-left-grade">
                  <span>年度 : {{ item.yearName == null ? item.name : item.yearName }}</span>
                  <span style="color: #939393;margin: 0px 10px;">|</span>
                  <span v-if="item.rule[0]">方向 : {{ item.rule[0].courseTypeName }}</span>
                </span>
                <span style="display: block;font-size: 14px;margin-bottom: 5px;">申报级别：
                  {{ item.declareLevel }}
                </span>
                <div class="certificateList-item-left-detail">
                  <span>申请标准：</span>
                  <span type="info" v-if="typeof (item.rule) == 'string'">
                    {{ item.rule }}
                  </span>
                  <span style="margin-right: 15px;display: inline-block;" type="info"
                    v-if="typeof (item.rule) == 'object'" v-for="(index) in item.rule">
                    {{ index.courseTypeName }}需要{{ index.requiredHours }}学时
                  </span>
                </div>
              </div>
              <!-- <span>￥{{ item.price }}</span> -->
              <div>
                <!-- <el-button plain @click.native.stop="deleteItem1(item)">申请标准</el-button> -->
                <el-button type="primary" :disabled="item.isget" plain
                  @click.native.stop="applyCertificateBtn(item)">申请证书</el-button>
              </div>
            </div>
          </div>
          <my-dialog title="申请标准" :visible.sync="deleteFlag1" @before-close="refreshLX1" width="363px">
            <div class="message" v-for="(item, index) in this.popupMsg">
              {{ item.courseTypeName }}:{{ item.requiredHours }}学时
            </div>
            <el-row>
              <el-col class="flex_end_j" style="text-align: center;">
                <div class="flex_cancel" style="text-align: center;" @click="deleteFlag1 = false">确定</div>
              </el-col>
            </el-row>
          </my-dialog>

          <my-dialog title="申请证书" :visible.sync="deleteFlag" @before-close="refreshLX" width="363px">
            <div class="message">
              是否确认申请
            </div>

            <el-row>
              <el-col class="flex_end_j" style="text-align: center;">
                <div class="flex_cancel" style="text-align: center;" @click="deleteFlag = false">取消
                </div>
                <div class="flex_confirm" @click="applyCertificate">确定
                </div>
              </el-col>
            </el-row>
          </my-dialog>
        </div>
        <!--分页组件-->
        <el-pagination style="background-color: #ffffff;padding:20px 0;text-align: center;" background
          @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize" :total="page.totalPage" layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>

    <!-- ======  底部  ====== -->
    <footerBottom></footerBottom>
  </div>
</template>

<script>
import { httpGet, httpPost, httpNPGet } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from '@/components/footer/footer'
var interval;
export default {
  name: "",
  data() {
    return {
      // 全部证书加载
      listItemLoading: true,
      //当前页面跳转传值
      pageIndex: 2,
      navList: ["首页", "选课中心", "帮助中心", "个人中心", "联系我们"],
      active: 0,
      translateX: 700,
      lineWidth: 32,
      // 年份状态
      yearState: 1,
      imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
      // 浮动框
      xPos: document.body.clientWidth - 500,
      yPos: 100,
      xin: true,
      yin: true,
      step: 1,
      delay: 20,
      height: 0,
      heightSet: 0,
      widthSet: 0,
      yon: 0,
      xon: 0,
      pause: true,
      promptShow: true,
      listItem: [],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 10,
        //总页数
        totalPage: 0,
      },
      deleteFlag: false,
      deleteFlag1: false,
      // 弹窗信息
      popupMsg: "",
      // 需要申请的证书id
      certificateId: "",
      // 需要申请的证书信息
      certificateMsg: ""
    };
  },
  components: {
    // myVideo:Video
    topNav,
    footerBottom
  },
  mounted() {
    this.getCourses(this.page);
  },
  methods: {
    //鼠标移入效果
    clearFdAd() {
      clearInterval(interval);
      document.getElementsByClassName('prompt-img')[0].style.display = 'none';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'block';
    },
    //鼠标移出效果
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
      document.getElementsByClassName('prompt-img')[0].style.display = 'block';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'none';
    },

    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle() {
      if (this.yearState == 1) {
        this.yearState = 2
      } else if (this.yearState == 2) {
        this.yearState = 1
      }
      this.getCourses();
    },
    getCourses() {
      this.listItemLoading = true;
      httpGet("/app/app-certificate/list",
        {
          state: this.yearState,
          size: this.page.pageSize,
          current: this.page.pageIndex,
        }
        , (res) => {
          this.page.totalPage = res.data.total;
          this.listItem = res.data.records;
          // this.listItem.direction = res.data.res.data.records.rule[0]
          this.listItem.forEach(index => {
            index.year = index.updated.split("-")[0]

            // index.direction = index.rule[0]
            // console.log(JSON.parse(index.rule))
            if (index.rule == "旧平台证书") {
              // console.log("旧的")
            } else {
              index.rule = JSON.parse(index.rule)

            }
          })
          // console.log(res.data)
          this.listItemLoading = false;
        }, (err) => {
          if (err.code == 500) {
            if (err.msg == "token异常，请重新登录") {
              localStorage.clear();
              if (!localStorage.getItem("userId")) {
                this.$message.error('登录来查看证书!')
                this.$router.push("/login")
              }
            }
          }

          throw err
        })
    },
    refreshLX1() {
      this.deleteFlag1 = false;
    },
    deleteItem1(item) {
      // console.log(item.id)
      httpGet("/app/app-certificate/certificate-rule",
        {
          certificateId: item.id
        }, (res) => {
          // console.log(res.data)
          this.popupMsg = res.data

          this.deleteFlag1 = true;
          this.deleteFlag1 = false;
          this.deleteFlag1 = true;
        }, (err) => {

          throw err
        })
    },
    refreshLX() {
      this.deleteFlag = false;
    },
    applyCertificateBtn(item) {
      // console.log(item)
      if (this.$store.getters.oatoken) {
        this.$router.push({
          name: "CertificateDetail",
          query:{
            certificateData: JSON.stringify(item)
          }
        })

        // console.log('------证书id', item.id)
      }
      // else{
      //   // this.$message.error("请先登录")
      //   // this.$router.push({
      //   //   path: "/login"
      //   // })
      // }
    },
    applyCertificate() {
      httpGet("/app/app-certificate/validate",
        {
          certificateId: this.certificateId,
          userId: localStorage.getItem('userId'),
        }
        , (res) => {
          this.$message({
            message: '申请成功',
            type: 'success'
          });
          // console.log(this.certificateMsg)
          // console.log(res.data)
          this.$router.push({
            name: "Confirmorder",
            params: {
              data: res.data,
              certificateId: this.certificateId,
              certificateMsg: this.certificateMsg,
            },
          }
          )
          this.deleteFlag = false;
        }, (err) => {
          if (err.msg == "学时不满足证书要求") {
            // 报错信息
            this.$message.error(err.msg)
            this.deleteFlag = false;
          }
          if (err.msg == "证书已申请") {
            // 报错信息
            this.$message.error(err.msg)
            this.deleteFlag = false;
          }
          if (err.code == 500) {
            if (err.msg == "token异常，请重新登录") {
              localStorage.clear();
              if (!localStorage.getItem("userId")) {
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
          }
        })
    },
  },


}
</script>

<style lang="scss" scoped>
.logo {
  width: 375px;
  height: 80px;
}

.container {
  width: 100%;
  // max-width: 1200px;
  // background-color: rgb(247, 247, 247);
  // margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //position: fixed;
  //top: 0;
  //left: 0;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */

    .nav-left h2 {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: 2px;
    }

    .nav-left span {
      width: 1px;
      height: 26px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      margin: 0 15px;
      opacity: 0.2;
    }
  }

  .nav-center {
    width: 600px;

    .nav-list {
      /* margin: 0 auto;
      max-width: 600px; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
    }

    .nav-list>div {
      width: 600px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      border-right: solid 0.2px #d0d0d0;
    }

    .nav-list>div:last-child {
      border: none;
    }

    .nav-list--active {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
    }
  }

  .nav-line {
    margin: 0 auto;
  }

  .nav-line>div {
    /*width: 32px;*/
    height: 4px;
    background-color: #1371E7;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
  }

  .nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }


}

.typer {
  width: 61.458%;
  text-align: left;
  margin: 0 auto 1.25rem auto;
  padding-bottom: 1.25rem;
  border-bottom: solid 0.0625rem #EDEFF2;

  .move {
    height: 1.9375rem;
    overflow: hidden;
    transition: height .3s;
  }

  .typer-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8125rem;

    span {
      padding-top: 0.1875rem;
    }

    ::v-deep .el-radio__inner {
      display: none;
    }

    ::v-deep .el-radio {
      border-radius: 4px 4px 4px 4px;
      padding: 0 8px;
      height: 30px;
      line-height: 28px;
      border: 1px solid #EDEFF2;
      margin: 0 12px 20px 12px;
      cursor: pointer;
      font-size: 0.8125rem;
    }

    ::v-deep .el-radio:hover {
      color: #1371E7;
    }

    ::v-deep .el-radio__input.is-checked+.el-radio__label {
      color: #1371E7;
    }

    ::v-deep .el-radio.is-checked {
      color: #2080F7;
      background: rgba(32, 128, 247, 0.1);
    }

    ::v-deep .el-radio__label {
      padding-left: 0;
    }
  }
}

.all {
  padding: 32px 0;
  max-width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;

  //.active {
  //  color: red;
  //}
  //
  //.blue {
  //  color: #1371E7;
  //}

  .all-book {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 22px 32px;
    background: #fff;
    color: #9d2626;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #667280;

    h3 {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }

  .year-btn {
    // padding: 8px 32px;
    background-color: #fff;
    box-sizing: border-box;
    justify-content: start;
    display: flex;
    margin-top: -2px;
    width: 48px;
    float: left;
    // width: 100%;
    // padding: 8px 12px;
    // background: #EBECF0;

    .year-word {
      color: #1371E7;
      font-weight: 500;
      line-height: 32px;
      font-size: 14px;
    }
  }


  .certificateList {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 32px 0;
    margin-top: 20px;
    min-height: 600px;

    .certificateList-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      border-bottom: 3px solid rgb(247, 247, 247);

      .certificateList-item-left {
        display: flex;
        flex-direction: column;

        .certificateList-item-left-title {
          text-align: left;
          font-size: 18px;
          color: #3E454D;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 900;
        }

        .certificateList-item-left-grade {
          margin-top: 5px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          padding: 5px 0 5px;
          text-align: left;
          color: #3E454D;
        }

        .certificateList-item-left-detail {
          display: flex;
          font-size: 14px;
        }
      }

      &>span {
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #EB5757;
      }
    }
  }

}

.message {
  font-size: 1.125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5rem;
  text-align: left;
  padding: 0.75rem 6.61157% 3rem 8.8154%;
}

.message::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/img/pre/icon-tip-1.png');
  background-size: 100% 100%;
  margin-right: 4px;
}

.flex_end_j {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  padding-right: 4.40771%;
  height: 3.25rem;
  border-top: 0.0625rem solid #EDEFF2;

  .flex_cancel {
    width: 15.97796%;
    height: 2rem;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    border: 0.0625rem solid #E6E8EB;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    margin-right: 2.75482%;
    cursor: pointer;
  }

  .flex_confirm {
    display: flex;
    justify-content: center;
    width: 15.97796%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    cursor: pointer;
  }
}

// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
//   color: #FFF;
//   width: 66px;
//   height: 36px;
//   line-height: 36px;
//   background: #1371E7;
//   border-radius: 2px 2px 2px 2px;
//   opacity: 1;
//   border: 1px solid #EDEFF2;
// }

// ::v-deep .el-pagination.is-background .btn-next,
// ::v-deep .el-pagination.is-background .btn-prev,
// ::v-deep .el-pagination.is-background .el-pager li {
//   width: 66px;
//   height: 36px;
//   background: #FFFFFF;
//   border-radius: 2px 2px 2px 2px;
//   opacity: 1;
//   border: 0.0625rem solid #EDEFF2;
//   border-radius: 2px;
// }

.bottom {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}
</style>
